@font-face {
  font-family: 'Linearicons';
  src: url('./fonts/Linearicons.eot');
  src: local('☺'), url('./fonts/Linearicons.woff') format('woff'),
    url('./fonts/Linearicons.ttf') format('truetype'),
    url('./fonts/Linearicons.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^='icon-'],
[class*=' icon-'] {
  font-family: 'Linearicons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Enable Ligatures ================ */
  -webkit-font-feature-settings: 'liga';
  -moz-font-feature-settings: 'liga=1';
  -moz-font-feature-settings: 'liga';
  -ms-font-feature-settings: 'liga' 1;
  -o-font-feature-settings: 'liga';
  font-feature-settings: 'liga';

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-home:before {
  content: '\e600';
}
.icon-home2:before {
  content: '\e601';
}
.icon-home3:before {
  content: '\e602';
}
.icon-home4:before {
  content: '\e603';
}
.icon-home5:before {
  content: '\e604';
}
.icon-home6:before {
  content: '\e605';
}
.icon-bathtub:before {
  content: '\e606';
}
.icon-toothbrush:before {
  content: '\e607';
}
.icon-bed:before {
  content: '\e608';
}
.icon-couch:before {
  content: '\e609';
}
.icon-chair:before {
  content: '\e60a';
}
.icon-city:before {
  content: '\e60b';
}
.icon-apartment:before {
  content: '\e60c';
}
.icon-pencil:before {
  content: '\e60d';
}
.icon-pencil2:before {
  content: '\e60e';
}
.icon-pen:before {
  content: '\e60f';
}
.icon-pencil3:before {
  content: '\e610';
}
.icon-eraser:before {
  content: '\e611';
}
.icon-pencil4:before {
  content: '\e612';
}
.icon-pencil5:before {
  content: '\e613';
}
.icon-feather:before {
  content: '\e614';
}
.icon-feather2:before {
  content: '\e615';
}
.icon-feather3:before {
  content: '\e616';
}
.icon-pen2:before {
  content: '\e617';
}
.icon-pen-add:before {
  content: '\e618';
}
.icon-pen-remove:before {
  content: '\e619';
}
.icon-vector:before {
  content: '\e61a';
}
.icon-pen3:before {
  content: '\e61b';
}
.icon-blog:before {
  content: '\e61c';
}
.icon-brush:before {
  content: '\e61d';
}
.icon-brush2:before {
  content: '\e61e';
}
.icon-spray:before {
  content: '\e61f';
}
.icon-paint-roller:before {
  content: '\e620';
}
.icon-stamp:before {
  content: '\e621';
}
.icon-tape:before {
  content: '\e622';
}
.icon-desk-tape:before {
  content: '\e623';
}
.icon-texture:before {
  content: '\e624';
}
.icon-eye-dropper:before {
  content: '\e625';
}
.icon-palette:before {
  content: '\e626';
}
.icon-color-sampler:before {
  content: '\e627';
}
.icon-bucket:before {
  content: '\e628';
}
.icon-gradient:before {
  content: '\e629';
}
.icon-gradient2:before {
  content: '\e62a';
}
.icon-magic-wand:before {
  content: '\e62b';
}
.icon-magnet:before {
  content: '\e62c';
}
.icon-pencil-ruler:before {
  content: '\e62d';
}
.icon-pencil-ruler2:before {
  content: '\e62e';
}
.icon-compass:before {
  content: '\e62f';
}
.icon-aim:before {
  content: '\e630';
}
.icon-gun:before {
  content: '\e631';
}
.icon-bottle:before {
  content: '\e632';
}
.icon-drop:before {
  content: '\e633';
}
.icon-drop-crossed:before {
  content: '\e634';
}
.icon-drop2:before {
  content: '\e635';
}
.icon-snow:before {
  content: '\e636';
}
.icon-snow2:before {
  content: '\e637';
}
.icon-fire:before {
  content: '\e638';
}
.icon-lighter:before {
  content: '\e639';
}
.icon-knife:before {
  content: '\e63a';
}
.icon-dagger:before {
  content: '\e63b';
}
.icon-tissue:before {
  content: '\e63c';
}
.icon-toilet-paper:before {
  content: '\e63d';
}
.icon-poop:before {
  content: '\e63e';
}
.icon-umbrella:before {
  content: '\e63f';
}
.icon-umbrella2:before {
  content: '\e640';
}
.icon-rain:before {
  content: '\e641';
}
.icon-tornado:before {
  content: '\e642';
}
.icon-wind:before {
  content: '\e643';
}
.icon-fan:before {
  content: '\e644';
}
.icon-contrast:before {
  content: '\e645';
}
.icon-sun-small:before {
  content: '\e646';
}
.icon-sun:before {
  content: '\e647';
}
.icon-sun2:before {
  content: '\e648';
}
.icon-moon:before {
  content: '\e649';
}
.icon-cloud:before {
  content: '\e64a';
}
.icon-cloud-upload:before {
  content: '\e64b';
}
.icon-cloud-download:before {
  content: '\e64c';
}
.icon-cloud-rain:before {
  content: '\e64d';
}
.icon-cloud-hailstones:before {
  content: '\e64e';
}
.icon-cloud-snow:before {
  content: '\e64f';
}
.icon-cloud-windy:before {
  content: '\e650';
}
.icon-sun-wind:before {
  content: '\e651';
}
.icon-cloud-fog:before {
  content: '\e652';
}
.icon-cloud-sun:before {
  content: '\e653';
}
.icon-cloud-lightning:before {
  content: '\e654';
}
.icon-cloud-sync:before {
  content: '\e655';
}
.icon-cloud-lock:before {
  content: '\e656';
}
.icon-cloud-gear:before {
  content: '\e657';
}
.icon-cloud-alert:before {
  content: '\e658';
}
.icon-cloud-check:before {
  content: '\e659';
}
.icon-cloud-cross:before {
  content: '\e65a';
}
.icon-cloud-crossed:before {
  content: '\e65b';
}
.icon-cloud-database:before {
  content: '\e65c';
}
.icon-database:before {
  content: '\e65d';
}
.icon-database-add:before {
  content: '\e65e';
}
.icon-database-remove:before {
  content: '\e65f';
}
.icon-database-lock:before {
  content: '\e660';
}
.icon-database-refresh:before {
  content: '\e661';
}
.icon-database-check:before {
  content: '\e662';
}
.icon-database-history:before {
  content: '\e663';
}
.icon-database-upload:before {
  content: '\e664';
}
.icon-database-download:before {
  content: '\e665';
}
.icon-server:before {
  content: '\e666';
}
.icon-shield:before {
  content: '\e667';
}
.icon-shield-check:before {
  content: '\e668';
}
.icon-shield-alert:before {
  content: '\e669';
}
.icon-shield-cross:before {
  content: '\e66a';
}
.icon-lock:before {
  content: '\e66b';
}
.icon-rotation-lock:before {
  content: '\e66c';
}
.icon-unlock:before {
  content: '\e66d';
}
.icon-key:before {
  content: '\e66e';
}
.icon-key-hole:before {
  content: '\e66f';
}
.icon-toggle-off:before {
  content: '\e670';
}
.icon-toggle-on:before {
  content: '\e671';
}
.icon-cog:before {
  content: '\e672';
}
.icon-cog2:before {
  content: '\e673';
}
.icon-wrench:before {
  content: '\e674';
}
.icon-screwdriver:before {
  content: '\e675';
}
.icon-hammer-wrench:before {
  content: '\e676';
}
.icon-hammer:before {
  content: '\e677';
}
.icon-saw:before {
  content: '\e678';
}
.icon-axe:before {
  content: '\e679';
}
.icon-axe2:before {
  content: '\e67a';
}
.icon-shovel:before {
  content: '\e67b';
}
.icon-pickaxe:before {
  content: '\e67c';
}
.icon-factory:before {
  content: '\e67d';
}
.icon-factory2:before {
  content: '\e67e';
}
.icon-recycle:before {
  content: '\e67f';
}
.icon-trash:before {
  content: '\e680';
}
.icon-trash2:before {
  content: '\e681';
}
.icon-trash3:before {
  content: '\e682';
}
.icon-broom:before {
  content: '\e683';
}
.icon-game:before {
  content: '\e684';
}
.icon-gamepad:before {
  content: '\e685';
}
.icon-joystick:before {
  content: '\e686';
}
.icon-dice:before {
  content: '\e687';
}
.icon-spades:before {
  content: '\e688';
}
.icon-diamonds:before {
  content: '\e689';
}
.icon-clubs:before {
  content: '\e68a';
}
.icon-hearts:before {
  content: '\e68b';
}
.icon-heart:before {
  content: '\e68c';
}
.icon-star:before {
  content: '\e68d';
}
.icon-star-half:before {
  content: '\e68e';
}
.icon-star-empty:before {
  content: '\e68f';
}
.icon-flag:before {
  content: '\e690';
}
.icon-flag2:before {
  content: '\e691';
}
.icon-flag3:before {
  content: '\e692';
}
.icon-mailbox-full:before {
  content: '\e693';
}
.icon-mailbox-empty:before {
  content: '\e694';
}
.icon-at-sign:before {
  content: '\e695';
}
.icon-envelope:before {
  content: '\e696';
}
.icon-envelope-open:before {
  content: '\e697';
}
.icon-paperclip:before {
  content: '\e698';
}
.icon-paper-plane:before {
  content: '\e699';
}
.icon-reply:before {
  content: '\e69a';
}
.icon-reply-all:before {
  content: '\e69b';
}
.icon-inbox:before {
  content: '\e69c';
}
.icon-inbox2:before {
  content: '\e69d';
}
.icon-outbox:before {
  content: '\e69e';
}
.icon-box:before {
  content: '\e69f';
}
.icon-archive:before {
  content: '\e6a0';
}
.icon-archive2:before {
  content: '\e6a1';
}
.icon-drawers:before {
  content: '\e6a2';
}
.icon-drawers2:before {
  content: '\e6a3';
}
.icon-drawers3:before {
  content: '\e6a4';
}
.icon-eye:before {
  content: '\e6a5';
}
.icon-eye-crossed:before {
  content: '\e6a6';
}
.icon-eye-plus:before {
  content: '\e6a7';
}
.icon-eye-minus:before {
  content: '\e6a8';
}
.icon-binoculars:before {
  content: '\e6a9';
}
.icon-binoculars2:before {
  content: '\e6aa';
}
.icon-hdd:before {
  content: '\e6ab';
}
.icon-hdd-down:before {
  content: '\e6ac';
}
.icon-hdd-up:before {
  content: '\e6ad';
}
.icon-floppy-disk:before {
  content: '\e6ae';
}
.icon-disc:before {
  content: '\e6af';
}
.icon-tape2:before {
  content: '\e6b0';
}
.icon-printer:before {
  content: '\e6b1';
}
.icon-shredder:before {
  content: '\e6b2';
}
.icon-file-empty:before {
  content: '\e6b3';
}
.icon-file-add:before {
  content: '\e6b4';
}
.icon-file-check:before {
  content: '\e6b5';
}
.icon-file-lock:before {
  content: '\e6b6';
}
.icon-files:before {
  content: '\e6b7';
}
.icon-copy:before {
  content: '\e6b8';
}
.icon-compare:before {
  content: '\e6b9';
}
.icon-folder:before {
  content: '\e6ba';
}
.icon-folder-search:before {
  content: '\e6bb';
}
.icon-folder-plus:before {
  content: '\e6bc';
}
.icon-folder-minus:before {
  content: '\e6bd';
}
.icon-folder-download:before {
  content: '\e6be';
}
.icon-folder-upload:before {
  content: '\e6bf';
}
.icon-folder-star:before {
  content: '\e6c0';
}
.icon-folder-heart:before {
  content: '\e6c1';
}
.icon-folder-user:before {
  content: '\e6c2';
}
.icon-folder-shared:before {
  content: '\e6c3';
}
.icon-folder-music:before {
  content: '\e6c4';
}
.icon-folder-picture:before {
  content: '\e6c5';
}
.icon-folder-film:before {
  content: '\e6c6';
}
.icon-scissors:before {
  content: '\e6c7';
}
.icon-paste:before {
  content: '\e6c8';
}
.icon-clipboard-empty:before {
  content: '\e6c9';
}
.icon-clipboard-pencil:before {
  content: '\e6ca';
}
.icon-clipboard-text:before {
  content: '\e6cb';
}
.icon-clipboard-check:before {
  content: '\e6cc';
}
.icon-clipboard-down:before {
  content: '\e6cd';
}
.icon-clipboard-left:before {
  content: '\e6ce';
}
.icon-clipboard-alert:before {
  content: '\e6cf';
}
.icon-clipboard-user:before {
  content: '\e6d0';
}
.icon-register:before {
  content: '\e6d1';
}
.icon-enter:before {
  content: '\e6d2';
}
.icon-exit:before {
  content: '\e6d3';
}
.icon-papers:before {
  content: '\e6d4';
}
.icon-news:before {
  content: '\e6d5';
}
.icon-reading:before {
  content: '\e6d6';
}
.icon-typewriter:before {
  content: '\e6d7';
}
.icon-document:before {
  content: '\e6d8';
}
.icon-document2:before {
  content: '\e6d9';
}
.icon-graduation-hat:before {
  content: '\e6da';
}
.icon-license:before {
  content: '\e6db';
}
.icon-license2:before {
  content: '\e6dc';
}
.icon-medal-empty:before {
  content: '\e6dd';
}
.icon-medal-first:before {
  content: '\e6de';
}
.icon-medal-second:before {
  content: '\e6df';
}
.icon-medal-third:before {
  content: '\e6e0';
}
.icon-podium:before {
  content: '\e6e1';
}
.icon-trophy:before {
  content: '\e6e2';
}
.icon-trophy2:before {
  content: '\e6e3';
}
.icon-music-note:before {
  content: '\e6e4';
}
.icon-music-note2:before {
  content: '\e6e5';
}
.icon-music-note3:before {
  content: '\e6e6';
}
.icon-playlist:before {
  content: '\e6e7';
}
.icon-playlist-add:before {
  content: '\e6e8';
}
.icon-guitar:before {
  content: '\e6e9';
}
.icon-trumpet:before {
  content: '\e6ea';
}
.icon-album:before {
  content: '\e6eb';
}
.icon-shuffle:before {
  content: '\e6ec';
}
.icon-repeat-one:before {
  content: '\e6ed';
}
.icon-repeat:before {
  content: '\e6ee';
}
.icon-headphones:before {
  content: '\e6ef';
}
.icon-headset:before {
  content: '\e6f0';
}
.icon-loudspeaker:before {
  content: '\e6f1';
}
.icon-equalizer:before {
  content: '\e6f2';
}
.icon-theater:before {
  content: '\e6f3';
}
.icon-3d-glasses:before {
  content: '\e6f4';
}
.icon-ticket:before {
  content: '\e6f5';
}
.icon-presentation:before {
  content: '\e6f6';
}
.icon-play:before {
  content: '\e6f7';
}
.icon-film-play:before {
  content: '\e6f8';
}
.icon-clapboard-play:before {
  content: '\e6f9';
}
.icon-media:before {
  content: '\e6fa';
}
.icon-film:before {
  content: '\e6fb';
}
.icon-film2:before {
  content: '\e6fc';
}
.icon-surveillance:before {
  content: '\e6fd';
}
.icon-surveillance2:before {
  content: '\e6fe';
}
.icon-camera:before {
  content: '\e6ff';
}
.icon-camera-crossed:before {
  content: '\e700';
}
.icon-camera-play:before {
  content: '\e701';
}
.icon-time-lapse:before {
  content: '\e702';
}
.icon-record:before {
  content: '\e703';
}
.icon-camera2:before {
  content: '\e704';
}
.icon-camera-flip:before {
  content: '\e705';
}
.icon-panorama:before {
  content: '\e706';
}
.icon-time-lapse2:before {
  content: '\e707';
}
.icon-shutter:before {
  content: '\e708';
}
.icon-shutter2:before {
  content: '\e709';
}
.icon-face-detection:before {
  content: '\e70a';
}
.icon-flare:before {
  content: '\e70b';
}
.icon-convex:before {
  content: '\e70c';
}
.icon-concave:before {
  content: '\e70d';
}
.icon-picture:before {
  content: '\e70e';
}
.icon-picture2:before {
  content: '\e70f';
}
.icon-picture3:before {
  content: '\e710';
}
.icon-pictures:before {
  content: '\e711';
}
.icon-book:before {
  content: '\e712';
}
.icon-audio-book:before {
  content: '\e713';
}
.icon-book2:before {
  content: '\e714';
}
.icon-bookmark:before {
  content: '\e715';
}
.icon-bookmark2:before {
  content: '\e716';
}
.icon-label:before {
  content: '\e717';
}
.icon-library:before {
  content: '\e718';
}
.icon-library2:before {
  content: '\e719';
}
.icon-contacts:before {
  content: '\e71a';
}
.icon-profile:before {
  content: '\e71b';
}
.icon-portrait:before {
  content: '\e71c';
}
.icon-portrait2:before {
  content: '\e71d';
}
.icon-user:before {
  content: '\e71e';
}
.icon-user-plus:before {
  content: '\e71f';
}
.icon-user-minus:before {
  content: '\e720';
}
.icon-user-lock:before {
  content: '\e721';
}
.icon-users:before {
  content: '\e722';
}
.icon-users2:before {
  content: '\e723';
}
.icon-users-plus:before {
  content: '\e724';
}
.icon-users-minus:before {
  content: '\e725';
}
.icon-group-work:before {
  content: '\e726';
}
.icon-woman:before {
  content: '\e727';
}
.icon-man:before {
  content: '\e728';
}
.icon-baby:before {
  content: '\e729';
}
.icon-baby2:before {
  content: '\e72a';
}
.icon-baby3:before {
  content: '\e72b';
}
.icon-baby-bottle:before {
  content: '\e72c';
}
.icon-walk:before {
  content: '\e72d';
}
.icon-hand-waving:before {
  content: '\e72e';
}
.icon-jump:before {
  content: '\e72f';
}
.icon-run:before {
  content: '\e730';
}
.icon-woman2:before {
  content: '\e731';
}
.icon-man2:before {
  content: '\e732';
}
.icon-man-woman:before {
  content: '\e733';
}
.icon-height:before {
  content: '\e734';
}
.icon-weight:before {
  content: '\e735';
}
.icon-scale:before {
  content: '\e736';
}
.icon-button:before {
  content: '\e737';
}
.icon-bow-tie:before {
  content: '\e738';
}
.icon-tie:before {
  content: '\e739';
}
.icon-socks:before {
  content: '\e73a';
}
.icon-shoe:before {
  content: '\e73b';
}
.icon-shoes:before {
  content: '\e73c';
}
.icon-hat:before {
  content: '\e73d';
}
.icon-pants:before {
  content: '\e73e';
}
.icon-shorts:before {
  content: '\e73f';
}
.icon-flip-flops:before {
  content: '\e740';
}
.icon-shirt:before {
  content: '\e741';
}
.icon-hanger:before {
  content: '\e742';
}
.icon-laundry:before {
  content: '\e743';
}
.icon-store:before {
  content: '\e744';
}
.icon-haircut:before {
  content: '\e745';
}
.icon-store-24:before {
  content: '\e746';
}
.icon-barcode:before {
  content: '\e747';
}
.icon-barcode2:before {
  content: '\e748';
}
.icon-barcode3:before {
  content: '\e749';
}
.icon-cashier:before {
  content: '\e74a';
}
.icon-bag:before {
  content: '\e74b';
}
.icon-bag2:before {
  content: '\e74c';
}
.icon-cart:before {
  content: '\e74d';
}
.icon-cart-empty:before {
  content: '\e74e';
}
.icon-cart-full:before {
  content: '\e74f';
}
.icon-cart-plus:before {
  content: '\e750';
}
.icon-cart-plus2:before {
  content: '\e751';
}
.icon-cart-add:before {
  content: '\e752';
}
.icon-cart-remove:before {
  content: '\e753';
}
.icon-cart-exchange:before {
  content: '\e754';
}
.icon-tag:before {
  content: '\e755';
}
.icon-tags:before {
  content: '\e756';
}
.icon-receipt:before {
  content: '\e757';
}
.icon-wallet:before {
  content: '\e758';
}
.icon-credit-card:before {
  content: '\e759';
}
.icon-cash-dollar:before {
  content: '\e75a';
}
.icon-cash-euro:before {
  content: '\e75b';
}
.icon-cash-pound:before {
  content: '\e75c';
}
.icon-cash-yen:before {
  content: '\e75d';
}
.icon-bag-dollar:before {
  content: '\e75e';
}
.icon-bag-euro:before {
  content: '\e75f';
}
.icon-bag-pound:before {
  content: '\e760';
}
.icon-bag-yen:before {
  content: '\e761';
}
.icon-coin-dollar:before {
  content: '\e762';
}
.icon-coin-euro:before {
  content: '\e763';
}
.icon-coin-pound:before {
  content: '\e764';
}
.icon-coin-yen:before {
  content: '\e765';
}
.icon-calculator:before {
  content: '\e766';
}
.icon-calculator2:before {
  content: '\e767';
}
.icon-abacus:before {
  content: '\e768';
}
.icon-vault:before {
  content: '\e769';
}
.icon-telephone:before {
  content: '\e76a';
}
.icon-phone-lock:before {
  content: '\e76b';
}
.icon-phone-wave:before {
  content: '\e76c';
}
.icon-phone-pause:before {
  content: '\e76d';
}
.icon-phone-outgoing:before {
  content: '\e76e';
}
.icon-phone-incoming:before {
  content: '\e76f';
}
.icon-phone-in-out:before {
  content: '\e770';
}
.icon-phone-error:before {
  content: '\e771';
}
.icon-phone-sip:before {
  content: '\e772';
}
.icon-phone-plus:before {
  content: '\e773';
}
.icon-phone-minus:before {
  content: '\e774';
}
.icon-voicemail:before {
  content: '\e775';
}
.icon-dial:before {
  content: '\e776';
}
.icon-telephone2:before {
  content: '\e777';
}
.icon-pushpin:before {
  content: '\e778';
}
.icon-pushpin2:before {
  content: '\e779';
}
.icon-map-marker:before {
  content: '\e77a';
}
.icon-map-marker-user:before {
  content: '\e77b';
}
.icon-map-marker-down:before {
  content: '\e77c';
}
.icon-map-marker-check:before {
  content: '\e77d';
}
.icon-map-marker-crossed:before {
  content: '\e77e';
}
.icon-radar:before {
  content: '\e77f';
}
.icon-compass2:before {
  content: '\e780';
}
.icon-map:before {
  content: '\e781';
}
.icon-map2:before {
  content: '\e782';
}
.icon-location:before {
  content: '\e783';
}
.icon-road-sign:before {
  content: '\e784';
}
.icon-calendar-empty:before {
  content: '\e785';
}
.icon-calendar-check:before {
  content: '\e786';
}
.icon-calendar-cross:before {
  content: '\e787';
}
.icon-calendar-31:before {
  content: '\e788';
}
.icon-calendar-full:before {
  content: '\e789';
}
.icon-calendar-insert:before {
  content: '\e78a';
}
.icon-calendar-text:before {
  content: '\e78b';
}
.icon-calendar-user:before {
  content: '\e78c';
}
.icon-mouse:before {
  content: '\e78d';
}
.icon-mouse-left:before {
  content: '\e78e';
}
.icon-mouse-right:before {
  content: '\e78f';
}
.icon-mouse-both:before {
  content: '\e790';
}
.icon-keyboard:before {
  content: '\e791';
}
.icon-keyboard-up:before {
  content: '\e792';
}
.icon-keyboard-down:before {
  content: '\e793';
}
.icon-delete:before {
  content: '\e794';
}
.icon-spell-check:before {
  content: '\e795';
}
.icon-escape:before {
  content: '\e796';
}
.icon-enter2:before {
  content: '\e797';
}
.icon-screen:before {
  content: '\e798';
}
.icon-aspect-ratio:before {
  content: '\e799';
}
.icon-signal:before {
  content: '\e79a';
}
.icon-signal-lock:before {
  content: '\e79b';
}
.icon-signal-80:before {
  content: '\e79c';
}
.icon-signal-60:before {
  content: '\e79d';
}
.icon-signal-40:before {
  content: '\e79e';
}
.icon-signal-20:before {
  content: '\e79f';
}
.icon-signal-0:before {
  content: '\e7a0';
}
.icon-signal-blocked:before {
  content: '\e7a1';
}
.icon-sim:before {
  content: '\e7a2';
}
.icon-flash-memory:before {
  content: '\e7a3';
}
.icon-usb-drive:before {
  content: '\e7a4';
}
.icon-phone:before {
  content: '\e7a5';
}
.icon-smartphone:before {
  content: '\e7a6';
}
.icon-smartphone-notification:before {
  content: '\e7a7';
}
.icon-smartphone-vibration:before {
  content: '\e7a8';
}
.icon-smartphone-embed:before {
  content: '\e7a9';
}
.icon-smartphone-waves:before {
  content: '\e7aa';
}
.icon-tablet:before {
  content: '\e7ab';
}
.icon-tablet2:before {
  content: '\e7ac';
}
.icon-laptop:before {
  content: '\e7ad';
}
.icon-laptop-phone:before {
  content: '\e7ae';
}
.icon-desktop:before {
  content: '\e7af';
}
.icon-launch:before {
  content: '\e7b0';
}
.icon-new-tab:before {
  content: '\e7b1';
}
.icon-window:before {
  content: '\e7b2';
}
.icon-cable:before {
  content: '\e7b3';
}
.icon-cable2:before {
  content: '\e7b4';
}
.icon-tv:before {
  content: '\e7b5';
}
.icon-radio:before {
  content: '\e7b6';
}
.icon-remote-control:before {
  content: '\e7b7';
}
.icon-power-switch:before {
  content: '\e7b8';
}
.icon-power:before {
  content: '\e7b9';
}
.icon-power-crossed:before {
  content: '\e7ba';
}
.icon-flash-auto:before {
  content: '\e7bb';
}
.icon-lamp:before {
  content: '\e7bc';
}
.icon-flashlight:before {
  content: '\e7bd';
}
.icon-lampshade:before {
  content: '\e7be';
}
.icon-cord:before {
  content: '\e7bf';
}
.icon-outlet:before {
  content: '\e7c0';
}
.icon-battery-power:before {
  content: '\e7c1';
}
.icon-battery-empty:before {
  content: '\e7c2';
}
.icon-battery-alert:before {
  content: '\e7c3';
}
.icon-battery-error:before {
  content: '\e7c4';
}
.icon-battery-low1:before {
  content: '\e7c5';
}
.icon-battery-low2:before {
  content: '\e7c6';
}
.icon-battery-low3:before {
  content: '\e7c7';
}
.icon-battery-mid1:before {
  content: '\e7c8';
}
.icon-battery-mid2:before {
  content: '\e7c9';
}
.icon-battery-mid3:before {
  content: '\e7ca';
}
.icon-battery-full:before {
  content: '\e7cb';
}
.icon-battery-charging:before {
  content: '\e7cc';
}
.icon-battery-charging2:before {
  content: '\e7cd';
}
.icon-battery-charging3:before {
  content: '\e7ce';
}
.icon-battery-charging4:before {
  content: '\e7cf';
}
.icon-battery-charging5:before {
  content: '\e7d0';
}
.icon-battery-charging6:before {
  content: '\e7d1';
}
.icon-battery-charging7:before {
  content: '\e7d2';
}
.icon-chip:before {
  content: '\e7d3';
}
.icon-chip-x64:before {
  content: '\e7d4';
}
.icon-chip-x86:before {
  content: '\e7d5';
}
.icon-bubble:before {
  content: '\e7d6';
}
.icon-bubbles:before {
  content: '\e7d7';
}
.icon-bubble-dots:before {
  content: '\e7d8';
}
.icon-bubble-alert:before {
  content: '\e7d9';
}
.icon-bubble-question:before {
  content: '\e7da';
}
.icon-bubble-text:before {
  content: '\e7db';
}
.icon-bubble-pencil:before {
  content: '\e7dc';
}
.icon-bubble-picture:before {
  content: '\e7dd';
}
.icon-bubble-video:before {
  content: '\e7de';
}
.icon-bubble-user:before {
  content: '\e7df';
}
.icon-bubble-quote:before {
  content: '\e7e0';
}
.icon-bubble-heart:before {
  content: '\e7e1';
}
.icon-bubble-emoticon:before {
  content: '\e7e2';
}
.icon-bubble-attachment:before {
  content: '\e7e3';
}
.icon-phone-bubble:before {
  content: '\e7e4';
}
.icon-quote-open:before {
  content: '\e7e5';
}
.icon-quote-close:before {
  content: '\e7e6';
}
.icon-dna:before {
  content: '\e7e7';
}
.icon-heart-pulse:before {
  content: '\e7e8';
}
.icon-pulse:before {
  content: '\e7e9';
}
.icon-syringe:before {
  content: '\e7ea';
}
.icon-pills:before {
  content: '\e7eb';
}
.icon-first-aid:before {
  content: '\e7ec';
}
.icon-lifebuoy:before {
  content: '\e7ed';
}
.icon-bandage:before {
  content: '\e7ee';
}
.icon-bandages:before {
  content: '\e7ef';
}
.icon-thermometer:before {
  content: '\e7f0';
}
.icon-microscope:before {
  content: '\e7f1';
}
.icon-brain:before {
  content: '\e7f2';
}
.icon-beaker:before {
  content: '\e7f3';
}
.icon-skull:before {
  content: '\e7f4';
}
.icon-bone:before {
  content: '\e7f5';
}
.icon-construction:before {
  content: '\e7f6';
}
.icon-construction-cone:before {
  content: '\e7f7';
}
.icon-pie-chart:before {
  content: '\e7f8';
}
.icon-pie-chart2:before {
  content: '\e7f9';
}
.icon-graph:before {
  content: '\e7fa';
}
.icon-chart-growth:before {
  content: '\e7fb';
}
.icon-chart-bars:before {
  content: '\e7fc';
}
.icon-chart-settings:before {
  content: '\e7fd';
}
.icon-cake:before {
  content: '\e7fe';
}
.icon-gift:before {
  content: '\e7ff';
}
.icon-balloon:before {
  content: '\e800';
}
.icon-rank:before {
  content: '\e801';
}
.icon-rank2:before {
  content: '\e802';
}
.icon-rank3:before {
  content: '\e803';
}
.icon-crown:before {
  content: '\e804';
}
.icon-lotus:before {
  content: '\e805';
}
.icon-diamond:before {
  content: '\e806';
}
.icon-diamond2:before {
  content: '\e807';
}
.icon-diamond3:before {
  content: '\e808';
}
.icon-diamond4:before {
  content: '\e809';
}
.icon-linearicons:before {
  content: '\e80a';
}
.icon-teacup:before {
  content: '\e80b';
}
.icon-teapot:before {
  content: '\e80c';
}
.icon-glass:before {
  content: '\e80d';
}
.icon-bottle2:before {
  content: '\e80e';
}
.icon-glass-cocktail:before {
  content: '\e80f';
}
.icon-glass2:before {
  content: '\e810';
}
.icon-dinner:before {
  content: '\e811';
}
.icon-dinner2:before {
  content: '\e812';
}
.icon-chef:before {
  content: '\e813';
}
.icon-scale2:before {
  content: '\e814';
}
.icon-egg:before {
  content: '\e815';
}
.icon-egg2:before {
  content: '\e816';
}
.icon-eggs:before {
  content: '\e817';
}
.icon-platter:before {
  content: '\e818';
}
.icon-steak:before {
  content: '\e819';
}
.icon-hamburger:before {
  content: '\e81a';
}
.icon-hotdog:before {
  content: '\e81b';
}
.icon-pizza:before {
  content: '\e81c';
}
.icon-sausage:before {
  content: '\e81d';
}
.icon-chicken:before {
  content: '\e81e';
}
.icon-fish:before {
  content: '\e81f';
}
.icon-carrot:before {
  content: '\e820';
}
.icon-cheese:before {
  content: '\e821';
}
.icon-bread:before {
  content: '\e822';
}
.icon-ice-cream:before {
  content: '\e823';
}
.icon-ice-cream2:before {
  content: '\e824';
}
.icon-candy:before {
  content: '\e825';
}
.icon-lollipop:before {
  content: '\e826';
}
.icon-coffee-bean:before {
  content: '\e827';
}
.icon-coffee-cup:before {
  content: '\e828';
}
.icon-cherry:before {
  content: '\e829';
}
.icon-grapes:before {
  content: '\e82a';
}
.icon-citrus:before {
  content: '\e82b';
}
.icon-apple:before {
  content: '\e82c';
}
.icon-leaf:before {
  content: '\e82d';
}
.icon-landscape:before {
  content: '\e82e';
}
.icon-pine-tree:before {
  content: '\e82f';
}
.icon-tree:before {
  content: '\e830';
}
.icon-cactus:before {
  content: '\e831';
}
.icon-paw:before {
  content: '\e832';
}
.icon-footprint:before {
  content: '\e833';
}
.icon-speed-slow:before {
  content: '\e834';
}
.icon-speed-medium:before {
  content: '\e835';
}
.icon-speed-fast:before {
  content: '\e836';
}
.icon-rocket:before {
  content: '\e837';
}
.icon-hammer2:before {
  content: '\e838';
}
.icon-balance:before {
  content: '\e839';
}
.icon-briefcase:before {
  content: '\e83a';
}
.icon-luggage-weight:before {
  content: '\e83b';
}
.icon-dolly:before {
  content: '\e83c';
}
.icon-plane:before {
  content: '\e83d';
}
.icon-plane-crossed:before {
  content: '\e83e';
}
.icon-helicopter:before {
  content: '\e83f';
}
.icon-traffic-lights:before {
  content: '\e840';
}
.icon-siren:before {
  content: '\e841';
}
.icon-road:before {
  content: '\e842';
}
.icon-engine:before {
  content: '\e843';
}
.icon-oil-pressure:before {
  content: '\e844';
}
.icon-coolant-temperature:before {
  content: '\e845';
}
.icon-car-battery:before {
  content: '\e846';
}
.icon-gas:before {
  content: '\e847';
}
.icon-gallon:before {
  content: '\e848';
}
.icon-transmission:before {
  content: '\e849';
}
.icon-car:before {
  content: '\e84a';
}
.icon-car-wash:before {
  content: '\e84b';
}
.icon-car-wash2:before {
  content: '\e84c';
}
.icon-bus:before {
  content: '\e84d';
}
.icon-bus2:before {
  content: '\e84e';
}
.icon-car2:before {
  content: '\e84f';
}
.icon-parking:before {
  content: '\e850';
}
.icon-car-lock:before {
  content: '\e851';
}
.icon-taxi:before {
  content: '\e852';
}
.icon-car-siren:before {
  content: '\e853';
}
.icon-car-wash3:before {
  content: '\e854';
}
.icon-car-wash4:before {
  content: '\e855';
}
.icon-ambulance:before {
  content: '\e856';
}
.icon-truck:before {
  content: '\e857';
}
.icon-trailer:before {
  content: '\e858';
}
.icon-scale-truck:before {
  content: '\e859';
}
.icon-train:before {
  content: '\e85a';
}
.icon-ship:before {
  content: '\e85b';
}
.icon-ship2:before {
  content: '\e85c';
}
.icon-anchor:before {
  content: '\e85d';
}
.icon-boat:before {
  content: '\e85e';
}
.icon-bicycle:before {
  content: '\e85f';
}
.icon-bicycle2:before {
  content: '\e860';
}
.icon-dumbbell:before {
  content: '\e861';
}
.icon-bench-press:before {
  content: '\e862';
}
.icon-swim:before {
  content: '\e863';
}
.icon-football:before {
  content: '\e864';
}
.icon-baseball-bat:before {
  content: '\e865';
}
.icon-baseball:before {
  content: '\e866';
}
.icon-tennis:before {
  content: '\e867';
}
.icon-tennis2:before {
  content: '\e868';
}
.icon-ping-pong:before {
  content: '\e869';
}
.icon-hockey:before {
  content: '\e86a';
}
.icon-8ball:before {
  content: '\e86b';
}
.icon-bowling:before {
  content: '\e86c';
}
.icon-bowling-pins:before {
  content: '\e86d';
}
.icon-golf:before {
  content: '\e86e';
}
.icon-golf2:before {
  content: '\e86f';
}
.icon-archery:before {
  content: '\e870';
}
.icon-slingshot:before {
  content: '\e871';
}
.icon-soccer:before {
  content: '\e872';
}
.icon-basketball:before {
  content: '\e873';
}
.icon-cube:before {
  content: '\e874';
}
.icon-3d-rotate:before {
  content: '\e875';
}
.icon-puzzle:before {
  content: '\e876';
}
.icon-glasses:before {
  content: '\e877';
}
.icon-glasses2:before {
  content: '\e878';
}
.icon-accessibility:before {
  content: '\e879';
}
.icon-wheelchair:before {
  content: '\e87a';
}
.icon-wall:before {
  content: '\e87b';
}
.icon-fence:before {
  content: '\e87c';
}
.icon-wall2:before {
  content: '\e87d';
}
.icon-icons:before {
  content: '\e87e';
}
.icon-resize-handle:before {
  content: '\e87f';
}
.icon-icons2:before {
  content: '\e880';
}
.icon-select:before {
  content: '\e881';
}
.icon-select2:before {
  content: '\e882';
}
.icon-site-map:before {
  content: '\e883';
}
.icon-earth:before {
  content: '\e884';
}
.icon-earth-lock:before {
  content: '\e885';
}
.icon-network:before {
  content: '\e886';
}
.icon-network-lock:before {
  content: '\e887';
}
.icon-planet:before {
  content: '\e888';
}
.icon-happy:before {
  content: '\e889';
}
.icon-smile:before {
  content: '\e88a';
}
.icon-grin:before {
  content: '\e88b';
}
.icon-tongue:before {
  content: '\e88c';
}
.icon-sad:before {
  content: '\e88d';
}
.icon-wink:before {
  content: '\e88e';
}
.icon-dream:before {
  content: '\e88f';
}
.icon-shocked:before {
  content: '\e890';
}
.icon-shocked2:before {
  content: '\e891';
}
.icon-tongue2:before {
  content: '\e892';
}
.icon-neutral:before {
  content: '\e893';
}
.icon-happy-grin:before {
  content: '\e894';
}
.icon-cool:before {
  content: '\e895';
}
.icon-mad:before {
  content: '\e896';
}
.icon-grin-evil:before {
  content: '\e897';
}
.icon-evil:before {
  content: '\e898';
}
.icon-wow:before {
  content: '\e899';
}
.icon-annoyed:before {
  content: '\e89a';
}
.icon-wondering:before {
  content: '\e89b';
}
.icon-confused:before {
  content: '\e89c';
}
.icon-zipped:before {
  content: '\e89d';
}
.icon-grumpy:before {
  content: '\e89e';
}
.icon-mustache:before {
  content: '\e89f';
}
.icon-tombstone-hipster:before {
  content: '\e8a0';
}
.icon-tombstone:before {
  content: '\e8a1';
}
.icon-ghost:before {
  content: '\e8a2';
}
.icon-ghost-hipster:before {
  content: '\e8a3';
}
.icon-halloween:before {
  content: '\e8a4';
}
.icon-christmas:before {
  content: '\e8a5';
}
.icon-easter-egg:before {
  content: '\e8a6';
}
.icon-mustache2:before {
  content: '\e8a7';
}
.icon-mustache-glasses:before {
  content: '\e8a8';
}
.icon-pipe:before {
  content: '\e8a9';
}
.icon-alarm:before {
  content: '\e8aa';
}
.icon-alarm-add:before {
  content: '\e8ab';
}
.icon-alarm-snooze:before {
  content: '\e8ac';
}
.icon-alarm-ringing:before {
  content: '\e8ad';
}
.icon-bullhorn:before {
  content: '\e8ae';
}
.icon-hearing:before {
  content: '\e8af';
}
.icon-volume-high:before {
  content: '\e8b0';
}
.icon-volume-medium:before {
  content: '\e8b1';
}
.icon-volume-low:before {
  content: '\e8b2';
}
.icon-volume:before {
  content: '\e8b3';
}
.icon-mute:before {
  content: '\e8b4';
}
.icon-lan:before {
  content: '\e8b5';
}
.icon-lan2:before {
  content: '\e8b6';
}
.icon-wifi:before {
  content: '\e8b7';
}
.icon-wifi-lock:before {
  content: '\e8b8';
}
.icon-wifi-blocked:before {
  content: '\e8b9';
}
.icon-wifi-mid:before {
  content: '\e8ba';
}
.icon-wifi-low:before {
  content: '\e8bb';
}
.icon-wifi-low2:before {
  content: '\e8bc';
}
.icon-wifi-alert:before {
  content: '\e8bd';
}
.icon-wifi-alert-mid:before {
  content: '\e8be';
}
.icon-wifi-alert-low:before {
  content: '\e8bf';
}
.icon-wifi-alert-low2:before {
  content: '\e8c0';
}
.icon-stream:before {
  content: '\e8c1';
}
.icon-stream-check:before {
  content: '\e8c2';
}
.icon-stream-error:before {
  content: '\e8c3';
}
.icon-stream-alert:before {
  content: '\e8c4';
}
.icon-communication:before {
  content: '\e8c5';
}
.icon-communication-crossed:before {
  content: '\e8c6';
}
.icon-broadcast:before {
  content: '\e8c7';
}
.icon-antenna:before {
  content: '\e8c8';
}
.icon-satellite:before {
  content: '\e8c9';
}
.icon-satellite2:before {
  content: '\e8ca';
}
.icon-mic:before {
  content: '\e8cb';
}
.icon-mic-mute:before {
  content: '\e8cc';
}
.icon-mic2:before {
  content: '\e8cd';
}
.icon-spotlights:before {
  content: '\e8ce';
}
.icon-hourglass:before {
  content: '\e8cf';
}
.icon-loading:before {
  content: '\e8d0';
}
.icon-loading2:before {
  content: '\e8d1';
}
.icon-loading3:before {
  content: '\e8d2';
}
.icon-refresh:before {
  content: '\e8d3';
}
.icon-refresh2:before {
  content: '\e8d4';
}
.icon-undo:before {
  content: '\e8d5';
}
.icon-redo:before {
  content: '\e8d6';
}
.icon-jump2:before {
  content: '\e8d7';
}
.icon-undo2:before {
  content: '\e8d8';
}
.icon-redo2:before {
  content: '\e8d9';
}
.icon-sync:before {
  content: '\e8da';
}
.icon-repeat-one2:before {
  content: '\e8db';
}
.icon-sync-crossed:before {
  content: '\e8dc';
}
.icon-sync2:before {
  content: '\e8dd';
}
.icon-repeat-one3:before {
  content: '\e8de';
}
.icon-sync-crossed2:before {
  content: '\e8df';
}
.icon-return:before {
  content: '\e8e0';
}
.icon-return2:before {
  content: '\e8e1';
}
.icon-refund:before {
  content: '\e8e2';
}
.icon-history:before {
  content: '\e8e3';
}
.icon-history2:before {
  content: '\e8e4';
}
.icon-self-timer:before {
  content: '\e8e5';
}
.icon-clock:before {
  content: '\e8e6';
}
.icon-clock2:before {
  content: '\e8e7';
}
.icon-clock3:before {
  content: '\e8e8';
}
.icon-watch:before {
  content: '\e8e9';
}
.icon-alarm2:before {
  content: '\e8ea';
}
.icon-alarm-add2:before {
  content: '\e8eb';
}
.icon-alarm-remove:before {
  content: '\e8ec';
}
.icon-alarm-check:before {
  content: '\e8ed';
}
.icon-alarm-error:before {
  content: '\e8ee';
}
.icon-timer:before {
  content: '\e8ef';
}
.icon-timer-crossed:before {
  content: '\e8f0';
}
.icon-timer2:before {
  content: '\e8f1';
}
.icon-timer-crossed2:before {
  content: '\e8f2';
}
.icon-download:before {
  content: '\e8f3';
}
.icon-upload:before {
  content: '\e8f4';
}
.icon-download2:before {
  content: '\e8f5';
}
.icon-upload2:before {
  content: '\e8f6';
}
.icon-enter-up:before {
  content: '\e8f7';
}
.icon-enter-down:before {
  content: '\e8f8';
}
.icon-enter-left:before {
  content: '\e8f9';
}
.icon-enter-right:before {
  content: '\e8fa';
}
.icon-exit-up:before {
  content: '\e8fb';
}
.icon-exit-down:before {
  content: '\e8fc';
}
.icon-exit-left:before {
  content: '\e8fd';
}
.icon-exit-right:before {
  content: '\e8fe';
}
.icon-enter-up2:before {
  content: '\e8ff';
}
.icon-enter-down2:before {
  content: '\e900';
}
.icon-enter-vertical:before {
  content: '\e901';
}
.icon-enter-left2:before {
  content: '\e902';
}
.icon-enter-right2:before {
  content: '\e903';
}
.icon-enter-horizontal:before {
  content: '\e904';
}
.icon-exit-up2:before {
  content: '\e905';
}
.icon-exit-down2:before {
  content: '\e906';
}
.icon-exit-left2:before {
  content: '\e907';
}
.icon-exit-right2:before {
  content: '\e908';
}
.icon-cli:before {
  content: '\e909';
}
.icon-bug:before {
  content: '\e90a';
}
.icon-code:before {
  content: '\e90b';
}
.icon-file-code:before {
  content: '\e90c';
}
.icon-file-image:before {
  content: '\e90d';
}
.icon-file-zip:before {
  content: '\e90e';
}
.icon-file-audio:before {
  content: '\e90f';
}
.icon-file-video:before {
  content: '\e910';
}
.icon-file-preview:before {
  content: '\e911';
}
.icon-file-charts:before {
  content: '\e912';
}
.icon-file-stats:before {
  content: '\e913';
}
.icon-file-spreadsheet:before {
  content: '\e914';
}
.icon-link:before {
  content: '\e915';
}
.icon-unlink:before {
  content: '\e916';
}
.icon-link2:before {
  content: '\e917';
}
.icon-unlink2:before {
  content: '\e918';
}
.icon-thumbs-up:before {
  content: '\e919';
}
.icon-thumbs-down:before {
  content: '\e91a';
}
.icon-thumbs-up2:before {
  content: '\e91b';
}
.icon-thumbs-down2:before {
  content: '\e91c';
}
.icon-thumbs-up3:before {
  content: '\e91d';
}
.icon-thumbs-down3:before {
  content: '\e91e';
}
.icon-share:before {
  content: '\e91f';
}
.icon-share2:before {
  content: '\e920';
}
.icon-share3:before {
  content: '\e921';
}
.icon-magnifier:before {
  content: '\e922';
}
.icon-file-search:before {
  content: '\e923';
}
.icon-find-replace:before {
  content: '\e924';
}
.icon-zoom-in:before {
  content: '\e925';
}
.icon-zoom-out:before {
  content: '\e926';
}
.icon-loupe:before {
  content: '\e927';
}
.icon-loupe-zoom-in:before {
  content: '\e928';
}
.icon-loupe-zoom-out:before {
  content: '\e929';
}
.icon-cross:before {
  content: '\e92a';
}
.icon-menu:before {
  content: '\e92b';
}
.icon-list:before {
  content: '\e92c';
}
.icon-list2:before {
  content: '\e92d';
}
.icon-list3:before {
  content: '\e92e';
}
.icon-menu2:before {
  content: '\e92f';
}
.icon-list4:before {
  content: '\e930';
}
.icon-menu3:before {
  content: '\e931';
}
.icon-exclamation:before {
  content: '\e932';
}
.icon-question:before {
  content: '\e933';
}
.icon-check:before {
  content: '\e934';
}
.icon-cross2:before {
  content: '\e935';
}
.icon-plus:before {
  content: '\e936';
}
.icon-minus:before {
  content: '\e937';
}
.icon-percent:before {
  content: '\e938';
}
.icon-chevron-up:before {
  content: '\e939';
}
.icon-chevron-down:before {
  content: '\e93a';
}
.icon-chevron-left:before {
  content: '\e93b';
}
.icon-chevron-right:before {
  content: '\e93c';
}
.icon-chevrons-expand-vertical:before {
  content: '\e93d';
}
.icon-chevrons-expand-horizontal:before {
  content: '\e93e';
}
.icon-chevrons-contract-vertical:before {
  content: '\e93f';
}
.icon-chevrons-contract-horizontal:before {
  content: '\e940';
}
.icon-arrow-up:before {
  content: '\e941';
}
.icon-arrow-down:before {
  content: '\e942';
}
.icon-arrow-left:before {
  content: '\e943';
}
.icon-arrow-right:before {
  content: '\e944';
}
.icon-arrow-up-right:before {
  content: '\e945';
}
.icon-arrows-merge:before {
  content: '\e946';
}
.icon-arrows-split:before {
  content: '\e947';
}
.icon-arrow-divert:before {
  content: '\e948';
}
.icon-arrow-return:before {
  content: '\e949';
}
.icon-expand:before {
  content: '\e94a';
}
.icon-contract:before {
  content: '\e94b';
}
.icon-expand2:before {
  content: '\e94c';
}
.icon-contract2:before {
  content: '\e94d';
}
.icon-move:before {
  content: '\e94e';
}
.icon-tab:before {
  content: '\e94f';
}
.icon-arrow-wave:before {
  content: '\e950';
}
.icon-expand3:before {
  content: '\e951';
}
.icon-expand4:before {
  content: '\e952';
}
.icon-contract3:before {
  content: '\e953';
}
.icon-notification:before {
  content: '\e954';
}
.icon-warning:before {
  content: '\e955';
}
.icon-notification-circle:before {
  content: '\e956';
}
.icon-question-circle:before {
  content: '\e957';
}
.icon-menu-circle:before {
  content: '\e958';
}
.icon-checkmark-circle:before {
  content: '\e959';
}
.icon-cross-circle:before {
  content: '\e95a';
}
.icon-plus-circle:before {
  content: '\e95b';
}
.icon-circle-minus:before {
  content: '\e95c';
}
.icon-percent-circle:before {
  content: '\e95d';
}
.icon-arrow-up-circle:before {
  content: '\e95e';
}
.icon-arrow-down-circle:before {
  content: '\e95f';
}
.icon-arrow-left-circle:before {
  content: '\e960';
}
.icon-arrow-right-circle:before {
  content: '\e961';
}
.icon-chevron-up-circle:before {
  content: '\e962';
}
.icon-chevron-down-circle:before {
  content: '\e963';
}
.icon-chevron-left-circle:before {
  content: '\e964';
}
.icon-chevron-right-circle:before {
  content: '\e965';
}
.icon-backward-circle:before {
  content: '\e966';
}
.icon-first-circle:before {
  content: '\e967';
}
.icon-previous-circle:before {
  content: '\e968';
}
.icon-stop-circle:before {
  content: '\e969';
}
.icon-play-circle:before {
  content: '\e96a';
}
.icon-pause-circle:before {
  content: '\e96b';
}
.icon-next-circle:before {
  content: '\e96c';
}
.icon-last-circle:before {
  content: '\e96d';
}
.icon-forward-circle:before {
  content: '\e96e';
}
.icon-eject-circle:before {
  content: '\e96f';
}
.icon-crop:before {
  content: '\e970';
}
.icon-frame-expand:before {
  content: '\e971';
}
.icon-frame-contract:before {
  content: '\e972';
}
.icon-focus:before {
  content: '\e973';
}
.icon-transform:before {
  content: '\e974';
}
.icon-grid:before {
  content: '\e975';
}
.icon-grid-crossed:before {
  content: '\e976';
}
.icon-layers:before {
  content: '\e977';
}
.icon-layers-crossed:before {
  content: '\e978';
}
.icon-toggle:before {
  content: '\e979';
}
.icon-rulers:before {
  content: '\e97a';
}
.icon-ruler:before {
  content: '\e97b';
}
.icon-funnel:before {
  content: '\e97c';
}
.icon-flip-horizontal:before {
  content: '\e97d';
}
.icon-flip-vertical:before {
  content: '\e97e';
}
.icon-flip-horizontal2:before {
  content: '\e97f';
}
.icon-flip-vertical2:before {
  content: '\e980';
}
.icon-angle:before {
  content: '\e981';
}
.icon-angle2:before {
  content: '\e982';
}
.icon-subtract:before {
  content: '\e983';
}
.icon-combine:before {
  content: '\e984';
}
.icon-intersect:before {
  content: '\e985';
}
.icon-exclude:before {
  content: '\e986';
}
.icon-align-center-vertical:before {
  content: '\e987';
}
.icon-align-right:before {
  content: '\e988';
}
.icon-align-bottom:before {
  content: '\e989';
}
.icon-align-left:before {
  content: '\e98a';
}
.icon-align-center-horizontal:before {
  content: '\e98b';
}
.icon-align-top:before {
  content: '\e98c';
}
.icon-square:before {
  content: '\e98d';
}
.icon-plus-square:before {
  content: '\e98e';
}
.icon-minus-square:before {
  content: '\e98f';
}
.icon-percent-square:before {
  content: '\e990';
}
.icon-arrow-up-square:before {
  content: '\e991';
}
.icon-arrow-down-square:before {
  content: '\e992';
}
.icon-arrow-left-square:before {
  content: '\e993';
}
.icon-arrow-right-square:before {
  content: '\e994';
}
.icon-chevron-up-square:before {
  content: '\e995';
}
.icon-chevron-down-square:before {
  content: '\e996';
}
.icon-chevron-left-square:before {
  content: '\e997';
}
.icon-chevron-right-square:before {
  content: '\e998';
}
.icon-check-square:before {
  content: '\e999';
}
.icon-cross-square:before {
  content: '\e99a';
}
.icon-menu-square:before {
  content: '\e99b';
}
.icon-prohibited:before {
  content: '\e99c';
}
.icon-circle:before {
  content: '\e99d';
}
.icon-radio-button:before {
  content: '\e99e';
}
.icon-ligature:before {
  content: '\e99f';
}
.icon-text-format:before {
  content: '\e9a0';
}
.icon-text-format-remove:before {
  content: '\e9a1';
}
.icon-text-size:before {
  content: '\e9a2';
}
.icon-bold:before {
  content: '\e9a3';
}
.icon-italic:before {
  content: '\e9a4';
}
.icon-underline:before {
  content: '\e9a5';
}
.icon-strikethrough:before {
  content: '\e9a6';
}
.icon-highlight:before {
  content: '\e9a7';
}
.icon-text-align-left:before {
  content: '\e9a8';
}
.icon-text-align-center:before {
  content: '\e9a9';
}
.icon-text-align-right:before {
  content: '\e9aa';
}
.icon-text-align-justify:before {
  content: '\e9ab';
}
.icon-line-spacing:before {
  content: '\e9ac';
}
.icon-indent-increase:before {
  content: '\e9ad';
}
.icon-indent-decrease:before {
  content: '\e9ae';
}
.icon-text-wrap:before {
  content: '\e9af';
}
.icon-pilcrow:before {
  content: '\e9b0';
}
.icon-direction-ltr:before {
  content: '\e9b1';
}
.icon-direction-rtl:before {
  content: '\e9b2';
}
.icon-page-break:before {
  content: '\e9b3';
}
.icon-page-break2:before {
  content: '\e9b4';
}
.icon-sort-alpha-asc:before {
  content: '\e9b5';
}
.icon-sort-alpha-desc:before {
  content: '\e9b6';
}
.icon-sort-numeric-asc:before {
  content: '\e9b7';
}
.icon-sort-numeric-desc:before {
  content: '\e9b8';
}
.icon-sort-amount-asc:before {
  content: '\e9b9';
}
.icon-sort-amount-desc:before {
  content: '\e9ba';
}
.icon-sort-time-asc:before {
  content: '\e9bb';
}
.icon-sort-time-desc:before {
  content: '\e9bc';
}
.icon-sigma:before {
  content: '\e9bd';
}
.icon-pencil-line:before {
  content: '\e9be';
}
.icon-hand:before {
  content: '\e9bf';
}
.icon-pointer-up:before {
  content: '\e9c0';
}
.icon-pointer-right:before {
  content: '\e9c1';
}
.icon-pointer-down:before {
  content: '\e9c2';
}
.icon-pointer-left:before {
  content: '\e9c3';
}
.icon-finger-tap:before {
  content: '\e9c4';
}
.icon-fingers-tap:before {
  content: '\e9c5';
}
.icon-reminder:before {
  content: '\e9c6';
}
.icon-fingers-crossed:before {
  content: '\e9c7';
}
.icon-fingers-victory:before {
  content: '\e9c8';
}
.icon-gesture-zoom:before {
  content: '\e9c9';
}
.icon-gesture-pinch:before {
  content: '\e9ca';
}
.icon-fingers-scroll-horizontal:before {
  content: '\e9cb';
}
.icon-fingers-scroll-vertical:before {
  content: '\e9cc';
}
.icon-fingers-scroll-left:before {
  content: '\e9cd';
}
.icon-fingers-scroll-right:before {
  content: '\e9ce';
}
.icon-hand2:before {
  content: '\e9cf';
}
.icon-pointer-up2:before {
  content: '\e9d0';
}
.icon-pointer-right2:before {
  content: '\e9d1';
}
.icon-pointer-down2:before {
  content: '\e9d2';
}
.icon-pointer-left2:before {
  content: '\e9d3';
}
.icon-finger-tap2:before {
  content: '\e9d4';
}
.icon-fingers-tap2:before {
  content: '\e9d5';
}
.icon-reminder2:before {
  content: '\e9d6';
}
.icon-gesture-zoom2:before {
  content: '\e9d7';
}
.icon-gesture-pinch2:before {
  content: '\e9d8';
}
.icon-fingers-scroll-horizontal2:before {
  content: '\e9d9';
}
.icon-fingers-scroll-vertical2:before {
  content: '\e9da';
}
.icon-fingers-scroll-left2:before {
  content: '\e9db';
}
.icon-fingers-scroll-right2:before {
  content: '\e9dc';
}
.icon-fingers-scroll-vertical3:before {
  content: '\e9dd';
}
.icon-border-style:before {
  content: '\e9de';
}
.icon-border-all:before {
  content: '\e9df';
}
.icon-border-outer:before {
  content: '\e9e0';
}
.icon-border-inner:before {
  content: '\e9e1';
}
.icon-border-top:before {
  content: '\e9e2';
}
.icon-border-horizontal:before {
  content: '\e9e3';
}
.icon-border-bottom:before {
  content: '\e9e4';
}
.icon-border-left:before {
  content: '\e9e5';
}
.icon-border-vertical:before {
  content: '\e9e6';
}
.icon-border-right:before {
  content: '\e9e7';
}
.icon-border-none:before {
  content: '\e9e8';
}
.icon-ellipsis:before {
  content: '\e9e9';
}
.icon-uni21:before {
  content: '\21';
}
.icon-uni22:before {
  content: '\22';
}
.icon-uni23:before {
  content: '\23';
}
.icon-uni24:before {
  content: '\24';
}
.icon-uni25:before {
  content: '\25';
}
.icon-uni26:before {
  content: '\26';
}
.icon-uni27:before {
  content: '\27';
}
.icon-uni28:before {
  content: '\28';
}
.icon-uni29:before {
  content: '\29';
}
.icon-uni2a:before {
  content: '\2a';
}
.icon-uni2b:before {
  content: '\2b';
}
.icon-uni2c:before {
  content: '\2c';
}
.icon-uni2d:before {
  content: '\2d';
}
.icon-uni2e:before {
  content: '\2e';
}
.icon-uni2f:before {
  content: '\2f';
}
.icon-uni30:before {
  content: '\30';
}
.icon-uni31:before {
  content: '\31';
}
.icon-uni32:before {
  content: '\32';
}
.icon-uni33:before {
  content: '\33';
}
.icon-uni34:before {
  content: '\34';
}
.icon-uni35:before {
  content: '\35';
}
.icon-uni36:before {
  content: '\36';
}
.icon-uni37:before {
  content: '\37';
}
.icon-uni38:before {
  content: '\38';
}
.icon-uni39:before {
  content: '\39';
}
.icon-uni3a:before {
  content: '\3a';
}
.icon-uni3b:before {
  content: '\3b';
}
.icon-uni3c:before {
  content: '\3c';
}
.icon-uni3d:before {
  content: '\3d';
}
.icon-uni3e:before {
  content: '\3e';
}
.icon-uni3f:before {
  content: '\3f';
}
.icon-uni40:before {
  content: '\40';
}
.icon-uni41:before {
  content: '\41';
}
.icon-uni42:before {
  content: '\42';
}
.icon-uni43:before {
  content: '\43';
}
.icon-uni44:before {
  content: '\44';
}
.icon-uni45:before {
  content: '\45';
}
.icon-uni46:before {
  content: '\46';
}
.icon-uni47:before {
  content: '\47';
}
.icon-uni48:before {
  content: '\48';
}
.icon-uni49:before {
  content: '\49';
}
.icon-uni4a:before {
  content: '\4a';
}
.icon-uni4b:before {
  content: '\4b';
}
.icon-uni4c:before {
  content: '\4c';
}
.icon-uni4d:before {
  content: '\4d';
}
.icon-uni4e:before {
  content: '\4e';
}
.icon-uni4f:before {
  content: '\4f';
}
.icon-uni50:before {
  content: '\50';
}
.icon-uni51:before {
  content: '\51';
}
.icon-uni52:before {
  content: '\52';
}
.icon-uni53:before {
  content: '\53';
}
.icon-uni54:before {
  content: '\54';
}
.icon-uni55:before {
  content: '\55';
}
.icon-uni56:before {
  content: '\56';
}
.icon-uni57:before {
  content: '\57';
}
.icon-uni58:before {
  content: '\58';
}
.icon-uni59:before {
  content: '\59';
}
.icon-uni5a:before {
  content: '\5a';
}
.icon-uni5b:before {
  content: '\5b';
}
.icon-uni5c:before {
  content: '\5c';
}
.icon-uni5d:before {
  content: '\5d';
}
.icon-uni5e:before {
  content: '\5e';
}
.icon-uni5f:before {
  content: '\5f';
}
.icon-uni60:before {
  content: '\60';
}
.icon-uni61:before {
  content: '\61';
}
.icon-uni62:before {
  content: '\62';
}
.icon-uni63:before {
  content: '\63';
}
.icon-uni64:before {
  content: '\64';
}
.icon-uni65:before {
  content: '\65';
}
.icon-uni66:before {
  content: '\66';
}
.icon-uni67:before {
  content: '\67';
}
.icon-uni68:before {
  content: '\68';
}
.icon-uni69:before {
  content: '\69';
}
.icon-uni6a:before {
  content: '\6a';
}
.icon-uni6b:before {
  content: '\6b';
}
.icon-uni6c:before {
  content: '\6c';
}
.icon-uni6d:before {
  content: '\6d';
}
.icon-uni6e:before {
  content: '\6e';
}
.icon-uni6f:before {
  content: '\6f';
}
.icon-uni70:before {
  content: '\70';
}
.icon-uni71:before {
  content: '\71';
}
.icon-uni72:before {
  content: '\72';
}
.icon-uni73:before {
  content: '\73';
}
.icon-uni74:before {
  content: '\74';
}
.icon-uni75:before {
  content: '\75';
}
.icon-uni76:before {
  content: '\76';
}
.icon-uni77:before {
  content: '\77';
}
.icon-uni78:before {
  content: '\78';
}
.icon-uni79:before {
  content: '\79';
}
.icon-uni7a:before {
  content: '\7a';
}
.icon-uni7b:before {
  content: '\7b';
}
.icon-uni7c:before {
  content: '\7c';
}
.icon-uni7d:before {
  content: '\7d';
}
.icon-uni7e:before {
  content: '\7e';
}
.icon-copyright:before {
  content: '\a9';
}
