.ModalOverlayBase {
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
}

.D6ModalOverlay {
  z-index: 10;
}

.D6SessionTimeoutModalOverlay {
  z-index: 20;
}

.D6Modal {
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255) none repeat scroll 0 0;
  overflow: auto;
  margin: 20px;
  border-radius: 4px;
  outline: currentcolor none medium;
  align-self: center;
}

.D6LargeModal {
  width: 90vw;
  height: 90vh;
}
