.d6-table.ag-theme-alpine,
.d6-table.skinny.ag-theme-alpine {
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 16px;
}

.d6-table.ag-theme-alpine .ag-row,
.d6-table.skinny.ag-theme-alpine .ag-row {
  font-size: 16px;
}

.d6-table .ag-root-wrapper {
  border: none;
  border-radius: 0;
}

.d6-table .ag-header-cell {
  padding-left: 20px;
  padding-right: 20px;
  font-family: 'Barlow Semi Condensed', sans-serif;
}

.d6-table .ag-row {
  border-left: 0;
  border-right: 0;
  border-top: 0;
  background-color: #fff; /* disable striped rows */
}

.d6-table .ag-react-container {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.d6-table .ag-header-cell-sortable .ag-react-container {
  width: 100%;
}

.d6-table .ag-cell {
  padding-left: 19px; /* 1px less for border */
  padding-right: 19px; /* 1px less for border */
}

.d6-table .d6-text-cell {
  line-height: 58px; /* if changed, update DataTable.tsx */
}

.d6-table .ag-floating-filter-body .ag-react-container {
  overflow: visible;
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.d6-table .ag-floating-filter-button {
  display: none;
}

.d6-table .ag-header {
  overflow: visible;
  z-index: 1;
}

.d6-table .ag-header-row.ag-header-row-floating-filter {
  overflow: visible;
}

.d6-table .ag-header-viewport {
  overflow: visible;
}

/**
SKINNY TABLE STYLES
*/
.d6-table.skinny .ag-header-cell {
  background-color: #fff;
  padding-left: 16px;
  padding-right: 16px;
  font-family: 'Barlow Semi Condensed', sans-serif;
}

.d6-table.skinny.ag-theme-alpine .ag-header,
.d6-table.skinny.ag-theme-alpine .ag-row {
  border-color: #d9d9d9;
}

.d6-table.skinny .ag-react-container {
  width: 100%;
}

.d6-table.skinny .ag-cell {
  padding-left: 15px; /* 1px less for border */
  padding-right: 15px; /* 1px less for border */
}

.d6-table-mui-header {
  font-family: 'Barlow Semi Condensed', sans-serif;
  font-size: 16px;
}
