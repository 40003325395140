/*
 * we import css files here to ensure deterministic css import ordering
 * this way tailwind classes take priority over vendor styles
 * we don't have to do this for css modules or css-in-js, since that is scoped
 */
@import '../linearicons/linearicons.css';
@import 'react-toastify/dist/ReactToastify.css';
@import '@ag-grid-community/core/dist/styles/ag-grid.min.css';
@import '@ag-grid-community/core/dist/styles/ag-theme-alpine.min.css';
@import '../../../components/Table/Table.css';

@import 'tailwindcss/base';

body {
  @apply text-base;
  @apply text-black;
}

body,
html,
#root {
  height: 100%;
}

h1 {
  @apply text-2xl;
  @apply font-bold;
}

h2 {
  @apply text-lg;
  @apply font-bold;
  @apply mb-3;
}

/* apply space between adjacent paragraphs */
p + p {
  @apply mt-3;
}

.application-nav-bar a {
  @apply px-3;
  @apply py-2;
  @apply text-grey-77;
}

.application-nav-bar a:hover {
  @apply text-black;
}

.application-nav-bar a.active {
  @apply bg-grey-15;
  color: #555;
}

.study-details-nav > a {
  @apply py-3;
  @apply border-transparent;
  @apply border-b-2;
}

.study-details-nav > a.active {
  @apply text-orange;
  @apply border-orange;
}

.disabled {
  @apply text-grey-20;
  @apply pointer-events-none;
}

:root {
  --reach-menu-button: 1;
}

@import 'tailwindcss/components';

@import 'tailwindcss/utilities';
